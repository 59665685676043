<template>
  <div>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Cobranças">
        <Condicao url="/admin/condicoes/cobrancas" />
      </CTab>
      <CTab title="Planos de Pagamento">
        <Condicao url="/admin/condicoes/planos" />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import Condicao from './Condicao/Condicao';
import { loadDescs } from '../../helpers/utils';
export default {
  name: 'condicao-pagamento',
  components: {
    Condicao,
  },
  data() {
    return {
      descSingular: '',
      descPlural: '',
      activeTab: 'cobrancas',
    };
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
  },

  beforeMount() {
    const { descSingular, descPlural } = loadDescs('ADMIN_CONDICAO_PAGAMENTO');
    this.descSingular = descSingular;
    this.descPlural = descPlural;
  },
};
</script>
