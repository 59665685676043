<template>
  <modal name="editCondicao" height="auto" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div style="color: #999; font-size: 80%;">EDITANDO</div>
        <h2 class="title">
          <div>{{ condicao.desc }}</div>
        </h2>
      </div>

      <form>
        <div class="xrow">
          <div class="form-group">
            <div class="col-1-1">
              <label for>Acesso B2B</label>
              <select class="form-control" v-model="condicao.acessoB2B">
                <option :value="1">Sim</option>
                <option :value="0">Não</option>
              </select>
            </div>

            <div class="col-1-1">
              <label for>Acesso Call Center</label>
              <select class="form-control" v-model="condicao.acessoCC">
                <option :value="1">Sim</option>
                <option :value="0">Não</option>
              </select>
            </div>

            <div class="col-1-1">
              <label for>Acesso Força de Vendas</label>
              <select class="form-control" v-model="condicao.acessoFV">
                <option :value="1">Sim</option>
                <option :value="0">Não</option>
              </select>
            </div>

            <div class="col-1-1">
              <label for>Inclusão</label>
              <select class="form-control" v-model="condicao.inclusao">
                <option :value="1">Sim</option>
                <option :value="0">Não</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
        <button
          :class="{ 'button button-success': true}"
          v-on:click="handleSubmit"
        >Salvar</button>
        <button type="button" class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";

export default {
  name: "editCondicao",
  components: { CloseModal },
  props: ["condicao", "close", "salvar"],
  data() {
    return {};
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.salvar();
    },
  },
};
</script>
