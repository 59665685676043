<template>
  <div>
    <Edit :condicao="currentCondicao" :salvar="updateCondicao" :close="closeEdit"/>
    <Loading :isActive="isLoading"></Loading>
    <div class="filters" style="margin-top:0">
      <div>
        <span class="filters__search">
          <input type="text" v-model="searchTerm" placeholder="Buscar por...">
          <img
            style="position: relative; right: -10px;"
            src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
          >
        </span>
      </div>
    </div>

    <div v-if="error" class="bdti-alert bdti-alert-danger">{{ error.message }}</div>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Acesso B2B</th>
            <th>Acesso Call Center</th>
            <th>Acesso Força Vendas</th>
            <th>Plano de Inclusão?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="condicao in paginatedCondicoes" v-bind:key="condicao.id">
            <td>{{ condicao.id }}</td>
            <td>{{ condicao.desc }}</td>
            <td>
              <span
                v-bind:class="{ 'label' : true, 'success': condicao.acessoB2B, 'error': !condicao.acessoB2B }"
              >{{ intToYesOrNo(condicao.acessoB2B) }}</span>
            </td>
            <td>
              <span
                v-bind:class="{ 'label' : true, 'success': condicao.acessoCC, 'error': !condicao.acessoCC }"
              >{{ intToYesOrNo(condicao.acessoCC) }}</span>
            </td>
            <td>
              <span
                v-bind:class="{ 'label' : true, 'success': condicao.acessoFV, 'error': !condicao.acessoFV }"
              >{{ intToYesOrNo(condicao.acessoFV) }}</span>
            </td>
            <td>
              <span
                v-bind:class="{ 'label' : true, 'success': condicao.inclusao, 'error': !condicao.inclusao }"
              >{{ intToYesOrNo(condicao.inclusao) }}</span>
            </td>
            <td class="actions">
              <button class="button button-primary" v-on:click="openEdit(condicao.id)">
                <fa-icon icon="pencil-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: center; padding: 10px;">
      <pagination :data="paginator" :limit="5" @pagination-change-page="changePage"></pagination>
    </div>
  </div>
</template>

<script>
import { get, put } from "../../../helpers/apiRequest";
import { intToYesOrNo } from "../../../helpers/utils";

import Edit from "./Edit";

const PER_PAGE = 15;

const INITIAL = {
  id: "",
  desc: "Indefinido",
  acessoB2B: 0,
  acessoCC: 0,
  inclusao: 0
};

export default {
  name: "condicoes",
  props: ["url"],
  components: {
    Edit
  },
  data() {
    return {
      isLoading: false,
      error: null,

      searchTerm: "",

      condicoes: [],
      currentCondicao: {},

      currentPage: 1
    };
  },
  computed: {
    filteredCondicoes() {
      const filtered = this.searchCondicao() || [];
      return filtered;
    },
    paginatedCondicoes() {
      return this.paginateArray(this.filteredCondicoes);
    },
    paginator() {
      const itemsLength = this.filteredCondicoes.length;
      return {
        current_page: this.currentPage,
        last_page: Math.ceil(itemsLength / PER_PAGE),
        from: (this.currentPage - 1) * PER_PAGE,
        to: this.currentPage * PER_PAGE,
        per_page: PER_PAGE,
        total: itemsLength
      };
    }
  },
  methods: {
    searchCondicao() {
      const text = this.searchTerm.toLowerCase();

      if (!text) {
        return this.condicoes;
      }

      const regexp = new RegExp(`.*${text}.*`, "i");

      const filteredCondicoes = this.condicoes.filter(
        cob => regexp.test(cob.id) || regexp.test(cob.desc)
      );
      this.currentPage = 1;
      return filteredCondicoes;
    },
    openEdit(condicaoId) {
      this.currentCondicao = this.condicoes.find(
        cob => cob.id === condicaoId
      ) || { ...INITIAL };
      this.$modal.show("editCondicao");
    },
    closeEdit() {
      this.$modal.hide("editCondicao");
    },
    fetchCondicoes() {
      this.isLoading = true;
      get(this.url)
        .then(json => {
          this.condicoes = json;
          this.error = null;
        })
        .catch(err => {
          this.condicoes = [];
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCondicao() {
      this.isLoading = true;
      put(`${this.url}/${this.currentCondicao.id}`, this.currentCondicao)
        .then(json => {
          this.fetchCondicoes();
        })
        .catch(err => {
          this.error = err;
          this.condicoes = [];
        })
        .finally(() => {
          this.$modal.hide("editCondicao");
          this.isLoading = false;
        });
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },

    paginateArray(arr) {
      const from = (this.currentPage - 1) * PER_PAGE;
      const to = this.currentPage * PER_PAGE;
      return arr.slice(from, to);
    },

    intToYesOrNo: intToYesOrNo
  },
  beforeMount() {
    this.fetchCondicoes();
  }
};
</script>